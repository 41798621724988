import { createSlice } from '@reduxjs/toolkit';

const user = localStorage.getItem('user');

const initialState = { user: user ? JSON.parse(user) : null, users: [] };

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUser: (state, action) => {
      return { ...state, user: action.payload };
    },
    setUsers: (state, action) => {
      return { ...state, users: action.payload };
    },
    updateUsers: (state, action) => {
      return { ...state, users: state.users.map((user) => (user._id === action.payload._id ? action.payload : user)) };
    }
  }
});

export const { setUser, setUsers, updateUsers } = userSlice.actions;

export default userSlice.reducer;
