// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export const shoeTypes = ['Smooth', 'Rhythm', 'Blue with Yellow Heel', 'Other'];

export const API_CONSTANTS = {
  //BASE_URL: 'http://15.188.60.132:8080/api/',
  //BASE_URL: 'http://192.168.1.46:8080/api/',
  // BASE_URL: 'http://192.168.0.138:3333/',
  BASE_URL: 'https://api.joinsenseclub.com/',
  //BASE_URL: 'https://localhost:3333.com/',

  //API URLS
  sign_up_api_url: 'auth/signup',
  log_in_api_url: 'auth/login',

  get_all_categories_api_url: 'category/getAllCategories',
  get_all_categories_admin_panel_api_url: 'category/getAllCategoriesAdminPanel',
  get_all_topics_api_url: 'topic/getAllTopics',
  get_all_topics_admin_panel_api_url: 'topic/getAllTopicsAdminPanel',

  create_category_api_url: 'category/createCategory',
  update_category_api_url: 'category/updateCategory',
  create_topic_api_url: 'topic/createTopic',
  update_topic_api_url: 'topic/updateTopic',

  update_user_api_url: 'user/updateUser',
  update_user_by_id_api_url: 'user/updateUserById',
  get_user_by_id_api_url: 'user/getUserById',
  get_all_users_api_url: 'user/getAllUsers',
  follow_user_api_url: 'user/followUser',
  unfollow_user_api_url: 'user/unFollowUser',
  change_password_api_url: 'user/changePassword',

  create_post_api_url: 'post/createPost',
  get_all_posts_api_url: 'post/getAllPosts',
  get_posts_by_query_api_url: 'post/getPostsByQuery',
  update_post_api_url: 'post/updatePostById',
  like_post_api_url: 'post/likePost',
  unlike_post_api_url: 'post/unlikePost',
  get_post_by_id_api_url: 'post/getPostById',
  delete_post_by_id_api_url: 'post/deletePostById',

  create_offer_api_url: 'offer/createOffer',
  get_all_offers_api_url: 'offer/getAllOffers',
  get_offers_by_query_api_url: 'offer/getOffersByQuery',
  update_offer_api_url: 'offer/updateOfferById',
  like_offer_api_url: 'offer/likeOffer',
  unlike_offer_api_url: 'offer/unlikeOffer',

  create_comment_api_url: 'comment/createComment',
  delete_comment_api_url: 'comment/deleteComment',
  like_comment_api_url: 'comment/likeComment',
  unlike_comment_api_url: 'comment/unlikeComment',

  create_report_api_url: 'report/createReport',
  get_all_reports_api_url: 'report/getAllReports',
  update_report_api_url: 'report/updateReportById'
};
