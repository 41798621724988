import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories: (state, action) => {
      return action.payload;
    },
    addCategory: (state, action) => {
      return [...state, action.payload];
    },
    changeCategory: (state, action) => {
      return state.map((category) => (category._id === action.payload._id ? action.payload : category));
    }
  }
});

export const { setCategories, addCategory, changeCategory } = categoriesSlice.actions;

export default categoriesSlice.reducer;
