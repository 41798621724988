import { configureStore } from '@reduxjs/toolkit';
import usersReducer from 'redux/reducers/usersReducer';
import customizationReducer from 'redux/reducers/customizationReducer';
import categoriesReducer from 'redux/reducers/categoriesReducer';
import topicsReducer from 'redux/reducers/topicsReducer';

export default configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  reducer: {
    users: usersReducer,
    customization: customizationReducer,
    categories: categoriesReducer,
    topics: topicsReducer
  }
});
