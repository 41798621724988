import config from 'config';
import * as actionTypes from '../actions/customizationActions';

export const initialState = {
  isOpen: ['posts'], // for active default menu
  defaultId: 'posts',
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  opened: true,
  loading: false
};

const customizationReducer = (state = initialState, action) => {
  let id;
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      id = action.id;
      return {
        ...state,
        isOpen: [id]
      };
    case actionTypes.SET_MENU:
      return {
        ...state,
        opened: action.opened
      };
    case actionTypes.LOADING:
      return {
        ...state,
        loading: action.loading
      };
    default:
      return state;
  }
};

export default customizationReducer;
