import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from 'App';
import store from 'redux/store';
import 'assets/scss/style.scss';
import config from './config';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);
const queryClient = new QueryClient();

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename={config.basename}>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>
);
