import { useRoutes } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

export default function ThemeRoutes() {
  const token = localStorage.getItem('token');
  let routes;
  if (token) {
    routes = [MainRoutes];
  } else {
    routes = [AuthenticationRoutes];
  }

  return useRoutes(routes);
}
