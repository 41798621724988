import { IconDashboard } from '@tabler/icons';
const icons = { IconDashboard };

const dashboard = {
  id: 'dashboard',
  title: 'Dashboard',
  type: 'group',
  caption: '',
  children: [
    {
      id: 'posts',
      title: 'Posts',
      type: 'item',
      url: '/',
      // icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'categories',
      title: 'Categories',
      type: 'item',
      // icon: icons.IconDashboard,
      url: '/categories'
    },
    {
      id: 'topics',
      title: 'Topics',
      type: 'item',
      url: '/topics',
      // icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'users',
      title: 'Users',
      type: 'item',
      url: '/users',
      // icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'reports',
      title: 'Reports',
      type: 'item',
      url: '/reports',
      // icon: icons.IconDashboard,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
