import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

export const categoriesSlice = createSlice({
  name: 'topics',
  initialState,
  reducers: {
    setTopics: (state, action) => {
      return action.payload;
    },
    addTopics: (state, action) => {
      return [...state, action.payload];
    },
    changeTopic: (state, action) => {
      return state.map((topic) => (topic._id === action.payload._id ? action.payload : topic));
    }
  }
});

export const { setTopics, addTopics, changeTopic } = categoriesSlice.actions;

export default categoriesSlice.reducer;
