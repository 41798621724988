import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Categories = Loadable(lazy(() => import('views/categories')));
const Topics = Loadable(lazy(() => import('views/topics')));
const Posts = Loadable(lazy(() => import('views/posts')));
const Users = Loadable(lazy(() => import('views/users')));
const PostDetails = Loadable(lazy(() => import('views/postDetails')));
const Reports = Loadable(lazy(() => import('views/reports')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Posts />
    },
    {
      path: '/categories',
      element: <Categories />
    },
    {
      path: '/topics',
      element: <Topics />
    },
    {
      path: '/postDetails',
      element: <PostDetails />
    },
    {
      path: '/users',
      element: <Users />
    },
    {
      path: '/reports',
      element: <Reports />
    }
  ]
};

export default MainRoutes;
